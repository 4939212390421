import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configuredReduxStore from './store/configureStore';
import App from './app';
import { HashRouter } from 'react-router-dom';

import './services/prototype-service';
import './services/app-service.js';
import './styles/styles.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'aos/dist/aos.css';

ReactDOM.render(
   <Provider store={configuredReduxStore()}>
      <HashRouter>
         <React.StrictMode>
            <App />
         </React.StrictMode>
      </HashRouter>
   </Provider>,
   document.getElementById('wrapper')
);
