import React from 'react';
// import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { imgPaths } from './../../../store/carousel-store';

const GamePanelButton = ({
   text,
   style,
   transparent = true,
   disabled = false,
   onClick
}) => {
   const cssClassBase = 'game-panel-button';
   const cssClass = transparent
      ? `${cssClassBase} transparent`
      : 'game-panel-button';
   const btnIcon = transparent
      ? 'game-button-play-icon.png'
      : 'game-button-play-icon-white.png';

   return (
      <button
         className={cssClass}
         onClick={() => {
            if (onClick) onClick();
         }}
         type='button'
         style={style}
         disabled={disabled}
      >
         {text}
         <img src={`${imgPaths.landingpage}/${btnIcon}`} alt='stopwatch-icon' />
         {/* <IoIosArrowDroprightCircle className='icon-style' /> */}
      </button>
   );
};

export default GamePanelButton;
