import React from 'react';
import { GiCancel } from 'react-icons/gi';

const IzvuceniBrojeviScore = ({ text }) => {
   return (
      <div className='izvuceni-brojevi-score'>
         <span>
            <GiCancel />
         </span>
         <span>{text}</span>
      </div>
   );
};

export default IzvuceniBrojeviScore;
