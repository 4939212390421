import React, { useEffect } from 'react';
import Loto739RezultatiZaKolo from './loto739-rezultati-za-kolo';
import Loto739ArhivaRezultata from './loto739-arhiva-rezultata';
import { useDispatch, useSelector } from 'react-redux';
import { loadLotoData } from './../../store/loto-store';
import { getLatestDrawData } from './../../services/loto-service';
import { mapLotoDetailedReportToTableData } from '../../services/bingo-service';
import LoadingInformation from './../common/loading-information';

const Loto739Rezultati = (props) => {
   const dispatch = useDispatch();
   const { lotoReport, lotoDetailedReport, loaded } = useSelector(
      (state) => state.lotoStore
   );

   useEffect(() => {
      if (!lotoReport || lotoReport.length === 0) dispatch(loadLotoData());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   if (lotoReport.length === 0) return null;

   const handleOnDownloadClick = () => {
      console.log('download click...');
   };

   const { date, data } = getLatestDrawData(lotoReport);
   const tableData = mapLotoDetailedReportToTableData(lotoDetailedReport);

   return (
      <div className='loto-7-39-rezultati'>
         {!loaded && <LoadingInformation />}
         <Loto739RezultatiZaKolo
            date={date}
            data={data}
            tableData={tableData}
         />
         <Loto739ArhivaRezultata />
      </div>
   );
};

export default Loto739Rezultati;
