import React from 'react';
import Panel from '../../common/panel';
import Newsletter from './news-letter';
import { panelSizes } from './../../../services/panel-service';

const NewsletterContainer = (props) => {
   return (
      <div className='row'>
         <div className='col col-sm-12'>
            <Panel size={panelSizes.newsletterSmallRow}>
               <Newsletter />
            </Panel>
         </div>
      </div>
   );
};

export default NewsletterContainer;
