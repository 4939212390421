import React, { useEffect } from 'react';
import { handleOnInputChange } from './form-input-service';

const FormTextarea = ({
   label = '',
   name,
   placeholder = '',
   formState,
   schema,
   debounceDelay = 0,
   attributes = {},
   onChange
}) => {
   const [state, setState] = formState;

   useEffect(() => {
      if (!state.validationData[name]) return;

      const delayDebounceFn = setTimeout(() => {
         if (onChange) onChange();
      }, debounceDelay);

      return () => {
         clearTimeout(delayDebounceFn);
      };
   }, [state.validationData[name]]);

   const classBase = 'form-input';
   const className = label ? `${classBase} has-label` : `${classBase}`;

   return (
      <div className={className}>
         {label && <label htmlFor={name}>{label}</label>}
         {state.errors && state.errors[name] && (
            <span className='error'>{state.errors[name]}</span>
         )}
         <textarea
            rows='10'
            name={name}
            id={name}
            placeholder={placeholder}
            autoComplete='off'
            onChange={(e) => {
               e.e = e;
               e.state = state;
               e.setState = setState;
               e.schema = schema;
               handleOnInputChange(e);
            }}
            value={state.validationData[name]}
            {...attributes}
         />
      </div>
   );
};

export default FormTextarea;
