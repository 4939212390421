import React, { useEffect, useState } from 'react';
import GamePanel from './game-panel';
import { landingpage2RowColumnDefinition as colDef } from '../../../services/image-path-service';
import { landingpageFullRowDefinition as fullRowDef } from '../../../services/image-path-service';
import parseHTML from 'html-react-parser';
import Panel from './../../common/panel';
import { panelSizes } from './../../../services/panel-service';
import { useNavigate } from 'react-router-dom';
import { paths } from './../../../routes';
import { testdata } from './test-data';

const GamesContainer = ({ data }) => {
   const navigate = useNavigate();

   const handleAboutClick = () => {
      navigate(`${paths.loto7_39}/${paths.loto7_39_nested.oigri}`);
   };

   const handleResaultsClick = () => {
      navigate(`${paths.loto7_39}/${paths.loto7_39_nested.rezultati}`);
   };

   const [rowdata, fullrowdata] = data;
   console.log('rowdata', rowdata);
   // const dataReceived =   !rowdata && !rowdata.length && !fullrowdata && !fullrowdata.length;
   // if (!dataReceived) return null;

   return (
      <div className='games-container'>
         {/* <h1>Све игре</h1> */}

         {rowdata.map((rd, i) => (
            <div className='row' key={`rowcell${i}`}>
               {rd.map((d, j) => (
                  <div className={colDef} key={`row${i}cell${j}`}>
                     <Panel size={panelSizes.gameLargeCell}>
                        <GamePanel
                           title={d.title}
                           backgroundImage={d.backgroundImage}
                           logo={d.logo}
                           text={d.text}
                           showStopwatch={d.showStopWatch}
                           showBtnAbout={d.showBtnAbout}
                           showBtnResults={d.showBtnResults}
                           onAboutClick={handleAboutClick}
                           onResultsClick={handleResaultsClick}
                        />
                     </Panel>
                  </div>
               ))}
            </div>
         ))}

         {fullrowdata.map((row, i) => (
            <div className='row' key={`fullrowcell${i}`}>
               <div className={fullRowDef}>
                  <Panel size={panelSizes.gameSmallRow}>
                     <GamePanel
                        title={row.title}
                        backgroundImage={row.backgroundImage}
                        showBtnPlay={false}
                        showAsColumnPanel={false}
                     />
                  </Panel>
               </div>
            </div>
         ))}
      </div>
   );
};

export default GamesContainer;

{
   /* <GamePanel
   title='Бинго 34'
   info={parseHTML(
      `<span>Очекивани фондови</span>
                     <br>
                     Бинго 34
                     <br>
                     око 410.000 КМ
                     `
   )}
   showStopwatch={true}
   showBtnAbout={true}
   showBtnResults={true}
/>; */
}
