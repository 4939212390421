import React from 'react';
import NovostiItem from './novosti-item';

const Novosti = (props) => {
   return (
      <div className='novosti'>
         <NovostiItem />
      </div>
   );
};

export default Novosti;
