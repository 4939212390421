import React from 'react';
import GameFAQ from '../common/game-faq';
import OigriInfo from '../common/game-o-igri-info';
import { imgPaths } from './../../store/carousel-store';
import { data } from './test-data-loto739-oigri';
import { useSelector } from 'react-redux';
import { getFAQ } from '../../services/app-service';

const { about } = data;

const Loto739Oigri = (props) => {
   const { faq } = useSelector((state) => state.landingpageStore);

   return (
      <div className='game-o-igri'>
         <OigriInfo
            data={about}
            pathprefix={imgPaths.loto_sveoigri}
            additionClass='loto739-info'
         />
         <GameFAQ data={getFAQ('loto', faq)} header='Loto' />
         <GameFAQ data={getFAQ('lotoPlus', faq)} header='Loto Plus' />
         <GameFAQ data={getFAQ('lotoJoker', faq)} header='Joker' />
      </div>
   );
};

export default Loto739Oigri;
