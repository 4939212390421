const animationSpeedShow = 0.25;
const animationSpeedExit = 0.1;

export const defaultAnimation = {
   hidden: {
      opacity: 0,
      y: 30
   },
   visible: {
      opacity: 1,
      y: 0,
      transition: {
         duration: animationSpeedShow
      }
   },
   //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
   exit: {
      opacity: 0,
      y: 30,
      transition: {
         duration: animationSpeedExit,
         ease: 'easeOut'
      }
   }
};

export const opacityAnimation = {
   hidden: {
      opacity: 0
   },
   visible: {
      opacity: 1,
      transition: {
         duration: animationSpeedShow
      }
   },
   //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
   exit: {
      opacity: 0,
      transition: {
         duration: animationSpeedExit,
         ease: 'easeOut'
      }
   }
};

const exportObj = {
   default: defaultAnimation,
   opacity: opacityAnimation
};

export default exportObj;
