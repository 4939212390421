import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/common/footer';
import bingologo from '../img/bingo/bingo_logo.png';
import { bingoNavbarLinks } from '../routes';
import GameNavbar from './../components/common/game-navbar';

const BingoPage = (props) => {
   return (
      <>
         <GameNavbar
            logo={bingologo}
            links={bingoNavbarLinks}
            // additionClass='bingo-info'
         />
         <div className='bingo-page'>
            <Outlet />
         </div>
         <Footer />
      </>
   );
};

export default BingoPage;
