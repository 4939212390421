import React, { useEffect, useState } from 'react';
import OdabirArhive from '../common/odabir-arhive';
import DownloadPDF from './../common/download-pdf';
import Loto739RezultatiZaKolo from './loto739-rezultati-za-kolo';
import {
   getDrawRoundsForYear,
   getRoundData
} from '../../services/loto-service';
import { useSelector } from 'react-redux';
import { getDrawYears } from './../../services/loto-service';

const Loto739ArhivaRezultata = (props) => {
   const handleOnDownloadClick = () => {
      console.log('download Коло број 03. 19.02. 2022....');
   };

   const { lotoReport } = useSelector((state) => state.lotoStore);

   const [data, setData] = useState({});

   const [years, setYears] = useState([]);
   const [rounds, setRounds] = useState([]);

   const [selectedYear, setSelectedYear] = useState(0);
   const [selectedRound, setSelectedRound] = useState(0);

   useEffect(() => {
      setYears(getDrawYears(lotoReport));
      const godina = new Date().getFullYear();
      setRounds(getDrawRoundsForYear(lotoReport, godina));
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (years.length > 0) setSelectedYear(years[0].value);
   }, [years]);

   useEffect(() => {
      if (rounds.length > 0) setSelectedRound(rounds[0].value);
   }, [rounds]);

   const onRoundChange = (drawddl) => {
      const { value: round } = drawddl;
      setSelectedRound(round);
   };
   const onYearChange = (year) => {
      const { value: godina } = year;
      setSelectedYear(godina);
      setRounds(getDrawRoundsForYear(lotoReport, godina));
   };

   useEffect(() => {
      const data = getRoundData({
         allData: lotoReport,
         round: selectedRound,
         year: selectedYear
      });

      setData(data);
   }, [selectedRound, selectedYear]);

   useEffect(() => {}, [selectedYear]);

   return (
      <div className='loto739-arhiva-rezultata'>
         <OdabirArhive
            rounds={rounds}
            years={years}
            onRoundChange={onRoundChange}
            onYearChange={onYearChange}
            selectedRound={selectedRound}
            selectedYear={selectedYear}
         />
         <div style={{ marginBottom: '3rem' }}></div>

         {Object.keys(data).length > 0 && (
            <Loto739RezultatiZaKolo date={data.date} data={data.data} />
         )}
      </div>
   );
};

export default Loto739ArhivaRezultata;
