import React from 'react';
import BingoRezultatiZaKolo from './bingo-rezultati-za-kolo';

const BingoRezultati = (props) => {
   const handleOnDownloadClick = () => {
      console.log('download click...');
   };

   return (
      <div className='bingo-rezultati'>
         <BingoRezultatiZaKolo />
         {/* <Loto739ArhivaRezultata /> */}
      </div>
   );
};

export default BingoRezultati;
