import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../img/logo.png';
import ButtonResponsiveMenu from '../common/btn-responsive-menu';
import { bottomNavbarLinks, topNavbarLinks } from './../../routes';
import { getNavbarLinks } from './../../services/navbar-service';
import { useSelector } from 'react-redux';

const Navbar = (props) => {
   const { hideTopNavbar } = useSelector((state) => state.responsiveUtilities);

   return (
      <div className='navbar-rs-container'>
         <div className='navbar-rs'>
            <div className='navbar-rs-logo'>
               <NavLink to='landingpage'>
                  <img src={logo} alt='logo' />
               </NavLink>
            </div>
            <div className='navbar-rs-links'>
               <ButtonResponsiveMenu />
               {!hideTopNavbar && (
                  <div className='navbar-top'>
                     {getNavbarLinks(topNavbarLinks)}
                  </div>
               )}
               <div className='navbar-bottom'>
                  {getNavbarLinks(bottomNavbarLinks)}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Navbar;
