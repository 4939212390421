import React from 'react';
import Panel from './../common/panel';
import { panelSizes } from './../../services/panel-service';

const NovostiText = ({ header, date, text }) => {
   return (
      <div className='novosti-text'>
         <div className='novosti-header'>
            <div className='line'></div>
            <h2>{header}</h2>
         </div>
         <Panel size={panelSizes.novosti}>
            <div className='date'>{date}</div>
            <div className='text-container'>{text}</div>
         </Panel>
      </div>
   );
};

export default NovostiText;
