const initialState = {
   isSmartphoneResolution: false,
   isOpenResponsiveMenu: false,
   hideTopNavbar: false
};

const actions = {
   setIsSmartphoneResolution: 'SET_IS_SMARTPHONE_RESOLUTION',
   setResponsiveMenuOpenState: 'SET_RESPONSIVE_MENU_OPEN_STATE',
   hideTopNavbar: 'SET_ONSCROLL_CSS_CLASS_TO_BODY_ELEMENT'
};

export const setIsSmartphoneResolution = (isSmartphoneResolution) => ({
   type: actions.setIsSmartphoneResolution,
   payload: isSmartphoneResolution
});

export const setResponsiveMenuIsOpen = (openState) => ({
   type: actions.setResponsiveMenuOpenState,
   payload: openState
});

export const addScrollCSSclassToBody = (add) => ({
   type: actions.hideTopNavbar,
   payload: add
});

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case actions.setIsSmartphoneResolution:
         return { ...state, isSmartphoneResolution: action.payload };
      case actions.setResponsiveMenuOpenState:
         return { ...state, isOpenResponsiveMenu: action.payload };
      case actions.hideTopNavbar:
         return { ...state, hideTopNavbar: action.payload };
      default:
         return state;
   }
}
