import React from 'react';
import GameNumber from '../common/game-number';

const IzvuceniBingoBrojevi = ({ game, numbers, arhiva = false }) => {
   const getHeader = () => {
      switch (game) {
         case 'bingo':
            if (arhiva) return 'BINGO извучени бројеви';
            return (
               <>
                  Извучени бројеви за <span className='bingo'>BINGO</span>
               </>
            );
         case 'bingoPlus':
            if (arhiva) return 'BINGOPLUS извучени бројеви';
            return (
               <>
                  Извучени бројеви за{' '}
                  <span className='bingoPlus'>BINGO ПЛУС</span>
               </>
            );
         default:
            return '';
      }
   };

   return (
      <div className='izvuceni-bingo-brojevi-container'>
         <h2>{getHeader()}</h2>
         <div className='ib-container'>
            <div className='numbers-container'>
               {numbers.map((n, i) => (
                  <GameNumber number={n} game={game} key={`gameNumber${i}`} />
               ))}
            </div>
         </div>
      </div>
   );
};

export default IzvuceniBingoBrojevi;
