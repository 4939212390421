import React, { useEffect, useRef, useState } from 'react';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import { motion } from 'framer-motion';

const animationDuration = 0.3;
const ease = 'anticipate';

const TextExpander = ({ label, color = '#f00', text = '', children }) => {
   const [initRender, setInitRender] = useState(true);
   const [isOpen, setIsOpen] = useState(true);
   const [labelHeight, setLabelHeight] = useState(0);
   const [bodyHeight, setHeight] = useState(0);

   const refLabel = useRef(null);
   const refBody = useRef(null);

   useEffect(() => {
      setHeight(refBody.current?.clientHeight ?? 0);
      setLabelHeight(refLabel.current?.clientHeight ?? 0);

      setTimeout(() => {
         setIsOpen(false);
         setInitRender(false);
      }, 1);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='text-expander'>
         <div
            className='text-expander-container'
            onClick={() => setIsOpen(!isOpen)}
         >
            <motion.div
               style={{ overflow: 'hidden' }}
               className='text-expander-label'
               ref={refLabel}
               initial={{ height: initRender ?? 0, opacity: 0 }}
               animate={{
                  height: !isOpen ? labelHeight : 0,
                  opacity: isOpen ? 0 : 1,
                  transition: {
                     ease,
                     duration: initRender ? 0 : animationDuration,
                     delay: initRender
                        ? 0
                        : isOpen
                        ? 0
                        : animationDuration * 0.5
                  }
               }}
            >
               {label}
            </motion.div>
            <motion.div
               className='text-expander-body'
               style={{ overflow: 'hidden' }}
               ref={refBody}
               initial={{ height: initRender ?? 0, opacity: 0 }}
               animate={{
                  height: isOpen ? bodyHeight : 0,
                  opacity: isOpen ? 1 : 0,
                  transition: {
                     ease,
                     duration: initRender ? 0 : animationDuration,
                     delay: initRender
                        ? 0
                        : isOpen
                        ? animationDuration * 0.5
                        : 0
                  }
               }}
            >
               {children ?? text}
            </motion.div>
         </div>
         <div
            className='text-expander-icon'
            onClick={() => setIsOpen(!isOpen)}
            style={{ color }}
         >
            {!isOpen && <HiPlusSm />}
            {isOpen && <HiMinusSm />}
         </div>
      </div>
   );
};

export default TextExpander;
