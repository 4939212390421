import { combineReducers } from 'redux';
import responsiveUtilitiesReducers from './responsive-utilities-store';
import carouselReducers from './carousel-store';
import landingpageReducers from './landingpage-store';
import lotoReducers from './loto-store';
import bingoReducers from './bingo-store';

export default combineReducers({
   responsiveUtilities: responsiveUtilitiesReducers,
   carouselStore: carouselReducers,
   landingpageStore: landingpageReducers,
   lotoStore: lotoReducers,
   bingoStore: bingoReducers
});
