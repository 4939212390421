import React from 'react';
import InputTextWithCustomButton from '../../common/input-text-with-custom-button';

const Newsletter = (props) => {
   return (
      <div className='newsletter'>
         <div className='message'>
            <h2>Пријавите се за срећне вијести</h2>
            <p>
               Пратите наше вијести и ажурирања и будите међу првима који ће то
               искористити!
            </p>
         </div>
         <div className='input-email'>
            <InputTextWithCustomButton />
         </div>
      </div>
   );
};

export default Newsletter;
