import React from 'react';
import { isMobile } from 'react-device-detect';
import parseHTML from 'html-react-parser';

const GameInfoItem = ({ header, text, img, align = 'left' }) => {
   const alignStyle = () => {
      if (!isMobile)
         return { flexDirection: align === 'left' ? 'row' : 'row-reverse' };
      return { flexDirection: 'column' };
   };

   const aosValue = align === 'left' ? 'fade-left' : 'fade-right';
   const dataAOS = { ['data-aos']: aosValue };
   return (
      <div className='game-info-item' {...(!isMobile ? dataAOS : {})}>
         <div className='header-container' style={alignStyle()}>
            <div className='header'>{header}</div>
         </div>
         <div className='body-container' style={alignStyle()}>
            <div className='text'>{parseHTML(text)}</div>
            <div className='img'>
               <img src={img} alt='img' />
            </div>
         </div>
      </div>
   );
};

export default GameInfoItem;
