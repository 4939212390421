import React from 'react';
import GameNumber from './../common/game-number';
import IzvuceniBrojeviScore from './loto739-izvuceni-brojevi-score';

const IzvuceniLotoBrojevi = ({ game, numbers, score, arhiva = false }) => {
   const getHeader = () => {
      switch (game) {
         case 'loto':
            if (arhiva) return 'Лото извучени бројеви';
            return (
               <>
                  Извучени бројеви за <span className='loto'>ЛОТО 7/39</span>
               </>
            );
         case 'lotoPlus':
            if (arhiva) return 'Лото плус извучени бројеви';
            return (
               <>
                  Извучени бројеви за{' '}
                  <span className='lotoPlus'>ЛОТО ПЛУС</span>
               </>
            );
         case 'jokerPlus':
            if (arhiva) return 'ЏОКЕР број';
            return (
               <>
                  Извучен <span className='jokerPlus'>ЏОКЕР</span> број
               </>
            );
         default:
            return '';
      }
   };

   return (
      <div className='izvuceni-brojevi-container'>
         <h2>{getHeader()}</h2>
         <div className='ib-container'>
            <div className='numbers-container'>
               {numbers.map((n, i) => (
                  <GameNumber number={n} game={game} key={`gameNumber${i}`} />
               ))}
            </div>
            <div className='score-container'>
               {!score && (
                  <IzvuceniBrojeviScore text='Седмица није извучена!' />
               )}
            </div>
         </div>
      </div>
   );
};

export default IzvuceniLotoBrojevi;
