import Joi from 'joi-browser';

export const validateForm = (state, schema) => {
   //validiraj
   const objectToValidate = state.validationData;
   const options = {
      abortEarly: false,
      allowUnknown: true
   };
   const result = Joi.validate(objectToValidate, schema, options);
   // console.log(result);

   //ako je sve ok...
   if (!result.error) return null;

   //dodijeli greške errors objektu
   const errors = {};
   for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
   }

   return errors;
};

const validateProperty = ({ name, value }, _schema) => {
   //validira se samo jedno svojstvo validationData objekta
   const objectToValidate = { [name]: value };
   //shemu uzimam sa postojece sheme za validationData objekt
   const schema = { [name]: _schema[name] };

   const result = Joi.validate(objectToValidate, schema, {
      allowUnknown: true
   });
   return result.error ? result.error.details[0].message : null;
};

export const handleOnInputChange = ({
   target: input,
   state,
   setState: setStateFn,
   schema
}) => {
   const errors = { ...state.errors };
   const errorMessage = validateProperty(input, schema);

   if (errorMessage) errors[input.name] = errorMessage;
   else delete errors[input.name];

   //console.log(schema);
   const newState = { ...state };
   const validationData = { ...state.validationData };

   validationData[input.name] = input.value;
   newState.validationData = validationData;
   newState.errors = errors;

   setStateFn(newState);
};

// export const handleFormSubmit = (e, state, setState, schema, doSubmit) => {
//    console.log('e, setState, doSubmit', e, setState, doSubmit);
//    e.preventDefault();

//    const errors = validateForm(state, schema);
//    const newState = { ...state };
//    newState.errors = errors || {};
//    setState(newState);

//    doSubmit();
// };
