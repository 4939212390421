import React from 'react';
import { getNavLinkElements } from '../../services/loto-7-39-service';

const GameNavbar = ({ links, logo, additionClass }) => {
   const className = `game-navbar ${additionClass}`;
   return (
      <div className={className}>
         <div className='container'>
            <div className='logo-placeholder'>
               <img src={logo} alt='Loto logo' />
            </div>
            <div className='links'>{getNavLinkElements(links)}</div>
         </div>
      </div>
   );
};

export default GameNavbar;
