import React, { useEffect } from 'react';
import DownloadPDF from '../common/download-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { loadBingoData } from './../../store/bingo-store';
import { mapBingoReportToRenderData } from './../../services/bingo-service';
import IzvuceniBingoBrojevi from './bingo-izvuceni-brojevi';

const BingoRezultatiZaKolo = (props) => {
   const dispatch = useDispatch();
   const { bingoReport, bingoDetailedReport } = useSelector(
      (state) => state.bingoStore
   );

   useEffect(() => {
      dispatch(loadBingoData());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnDownloadClick = () => {
      console.log('download click...');
   };

   if (bingoReport.length === 0) return null;

   const bingoReportData = mapBingoReportToRenderData(bingoReport);

   return (
      <div className='bingo-rezultati-za-kolo'>
         <h1>РЕЗУЛТАТИ</h1>
         <DownloadPDF
            header='Извлачење 22.02.2022.'
            onClick={handleOnDownloadClick}
         />
         <div className='izvuceni-brojevi'>
            {bingoReportData.map((x, i) => (
               <IzvuceniBingoBrojevi
                  key={`bingoReport${i}`}
                  game={x.game}
                  numbers={x.numbers}
                  score={x.score}
               />
            ))}
         </div>
      </div>
   );
};

export default BingoRezultatiZaKolo;
