import Joi from 'joi-browser';

export const contactUsSchema = {
   ime: Joi.string()
      .required()
      .label('Име')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   prezime: Joi.string()
      .required()
      .label('Презиме')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   email: Joi.string()
      .email()
      .required()
      .label('Е-маил')
      .error((errors) => {
         if (errors[0].type === 'any.empty')
            return {
               message: `${errors[0].flags.label} je obavezan unos`
            };
         if (errors[0].type === 'string.email')
            return {
               message: 'pogrešna e-mail adresa'
            };
         return {
            message: `${errors[0].flags.label} - error.`
         };
      }),
   predmet: Joi.string()
      // .allow('')
      .optional()
      .max(100)
      .label('Предмет поруке')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   poruka: Joi.string()
      .required()
      .label('Ваша порука')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   napomena: Joi.any().optional()
};

const exportObj = {
   ContactUs: contactUsSchema
};

export default exportObj;
