import React, { useEffect } from 'react';
import DownloadPDF from '../common/download-pdf';
import TableIznosDobitakaLoto739 from './loto739-table-iznos-dobitaka';
import IzvuceniLotoBrojevi from './loto739-izvuceni-brojevi';
import { loadLotoData } from '../../store/loto-store';
import { useDispatch, useSelector } from 'react-redux';
import {
   getLatestDrawData,
   mapLotoDetailedReportToTableData
} from '../../services/loto-service';

const Loto739RezultatiZaKolo = ({ date, data, tableData }) => {
   const handleOnDownloadClick = () => {
      console.log('download click...');
   };

   return (
      <div className='loto739-rezultati-za-dan'>
         <h1>РЕЗУЛТАТИ</h1>
         <DownloadPDF
            header={`Извлачење ${date}`}
            onClick={handleOnDownloadClick}
         />
         <div className='izvuceni-brojevi'>
            {data.map((x, i) => (
               <IzvuceniLotoBrojevi
                  key={`lotoReport${i}`}
                  game={x.game}
                  numbers={x.numbers}
                  score={x.score}
               />
            ))}
         </div>
         {tableData && <TableIznosDobitakaLoto739 data={tableData} />}
      </div>
   );
};

export default Loto739RezultatiZaKolo;
