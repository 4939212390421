import React from 'react';
import SelectTransparent from './select-transparent';

const OdabirArhive = ({
   rounds,
   years,
   onRoundChange,
   onYearChange,
   selectedRound,
   selectedYear
}) => {
   return (
      <>
         <div className='odabir-arhive'>
            <h1>АРХИВА РЕЗУЛТАТА</h1>
            <span>ОДАБЕРИ</span>

            <SelectTransparent
               name='rounds'
               options={rounds}
               onChange={onRoundChange}
               value={selectedRound}
            />
            <SelectTransparent
               name='years'
               options={years}
               onChange={onYearChange}
               value={selectedYear}
            />
         </div>
      </>
   );
};

export default OdabirArhive;
