import React from 'react';
import DownloadPDFButton from './download-pdf-button';

const DownloadPDF = ({ header, buttonText = 'Скини у pdf-u', onClick }) => {
   return (
      <div className='download-pdf'>
         <header>{header}</header>
         <DownloadPDFButton text={buttonText} onClick={onClick} />
      </div>
   );
};

export default DownloadPDF;
