// endpoint vraca dd-mm-yyyy kojeg convertiram u new Date();
const convertToDateFormat = (date) => {
   const [yyyy, mm, dd] = date.split('T')[0].split('-');
   return new Date(yyyy, mm - 1, dd);
};

// const convertToJSONDateFormat = (date) => {
//    console.log('DATE', date);
//    const [dd, mm, yyyy] = date.split('-');
//    let newDate = new Date(yyyy, mm - 1, dd);

//    //offset radim jer mi toJSON() vraća vrijeme -2h
//    const offset = newDate.getTimezoneOffset();
//    newDate = new Date(newDate.getTime() - offset * 60 * 1000);
//    return newDate.toJSON().split('T')[0];
// };

/*
    {
        game:'loto' | 'lotoPlus' | 'jokerPlus',
        numbers: [],
        score: true | false,
        date: new Date(), 
        dateJSON: 2022-01-01
    }
*/
export const mapLotoReportToRenderData = (lotoReport, sort = 'desc') => {
   if (!lotoReport) return [];
   const getNumbers = (numbersString, sort = true) => {
      const _numbers = numbersString.split(',');
      return sort
         ? _numbers.map((x) => +x.trim()).sort((a, b) => a - b)
         : _numbers.map((x) => +x.trim());
   };

   const getScore = (id) => lotoReport.find((x) => x.id === id).jackpot_drawn;

   return lotoReport
      .map((x) => ({
         game: x.type,
         numbers: getNumbers(
            x.numbers_drawn,
            x.type.indexOf('joker') === 0 ? false : true
         ),
         score: getScore(x.id),
         date: convertToDateFormat(x.date),
         dateJSON: x.date.split('T')[0],
         year: x.year,
         round: x.round
      }))
      .sort(
         sort === 'desc' ? (a, b) => b.date - a.date : (a, b) => a.date - b.date
      );
};

/*
    član polja predstavlja podatak retka
    [
        {
            vrsta:'string',
            dobiciSrbija:'',
            dobiciRS:'',
            iznosDobitaka:''
        }
    ]
*/
export const mapLotoDetailedReportToTableData = (lotoDetailedReport) => {
   if (!lotoDetailedReport) return [];
   // lotoDetailedReport.sort((a, b) => +b.row_names - +a.row_names);
   const returnArray = [];
   for (let i = 7; i >= 1; i--) {
      const rowData = lotoDetailedReport.filter((x) => +x.row_names === i);
      returnArray.push(mapToTableRowDataObject(rowData));
   }
   return returnArray;
};

// fn prima polje objekata od kojih mapiram podatke na 1 objekt
const mapToTableRowDataObject = (arr) => ({
   vrsta: getVrstaName(arr, arr[0].row_names),
   dobiciSrbija: arr.find((x) => x.column_names === column_names.srbija).data,
   dobiciRS: arr.find((x) => x.column_names === column_names.republikaSrpska)
      .data,
   iznosDobitaka: arr.find((x) => x.column_names === column_names.iznosDobitaka)
      .data
});

export const getLatestDrawData = (lotoReport) => {
   const lotoReportData = mapLotoReportToRenderData(lotoReport);
   const grupiraniPodaci = lotoReportData.groupBy('dateJSON');

   let date = '';
   let data = [];
   /*
      Podaci su sortirani od najnovijeg prema najstarijem
      Grupiram podatke po datumu
      Uzmem prvu grupu podataka i ispišem ih 
   */
   for (const key in grupiraniPodaci) {
      date = new Date(key).toLocaleDateString();
      data = grupiraniPodaci[key];
      break;
   }

   return {
      date,
      data
   };
};

export const getRoundData = ({ allData, round, year }) => {
   const mapedData = mapLotoReportToRenderData(allData);
   const data = mapedData.filter((x) => x.round === round && x.year === year);

   const date =
      data.length !== 0 ? data[0].date.toLocaleDateString() : new Date();

   return {
      date,
      data
   };
};

export const getDrawYears = (lotoReport) => {
   const returnArray = [];
   const groupByYears = lotoReport.groupBy('year');

   for (const key in groupByYears) {
      returnArray.push({ text: key, value: +key });
   }

   returnArray.sort((a, b) => b.value - a.value);

   // return [{ text: 'Godina', value: 0 }, ...returnArray];
   return [...returnArray];
};

export const getDrawRoundsForYear = (lotoReport, year = 2022) => {
   const returnArray = [];
   lotoReport.forEach((x) => {
      if (x.year === year) returnArray.push(x.round);
   });
   const uniqueValues = [...new Set(returnArray)].sort((a, b) => a - b);

   // return [
   //    { text: 'Коло', value: 0 },
   //    ...uniqueValues.map((x) => ({ text: x, value: +x }))
   // ];
   return [...uniqueValues.map((x) => ({ text: x, value: +x }))];
};

//  TIME: 0-oduvijek, 1 - 1godina, 2 - 6 mjeseci
export const getStatistics = (data = [], game = 0, time = 0) => {
   const gameKeyValues = {
      0: 'loto',
      1: 'lotoPlus',
      2: 'jokerPlus'
   };
   const gameName = gameKeyValues[game];
   const allGroupedData = data.groupBy('type');
   let gameData = allGroupedData[gameName];

   const yearAgo = new Date().addMonths(-12);
   const sixMonthsAgo = new Date().addMonths(-6);

   let returnArray = [];
   let date = new Date();

   switch (time) {
      case 0:
         break;
      case 1:
         date = yearAgo;
         break;
      case 2:
         date = sixMonthsAgo;
         break;
      default:
         break;
   }

   switch (time) {
      case 0:
         returnArray = gameData;
         break;
      case 1:
         returnArray = gameData.filter((x) => {
            const gameDate = new Date(x.date);
            return gameDate > yearAgo;
         });
         break;
      case 2:
         returnArray = gameData.filter((x) => {
            const gameDate = new Date(x.date);
            return gameDate > sixMonthsAgo;
         });
         break;
      default:
         returnArray = [];
         break;
   }
   return {
      date,
      data: mapLotoReportToRenderData(returnArray)
   };
};

// Koliko puta se neki broj pojavljuje u polju...
export const getNumberOfOccurrences = (number, array) => {
   let occurrence = 0;
   array.forEach((n) => {
      if (n === number) occurrence++;
   });
   return occurrence;
};

export const getOccurrenceData = (data) => {
   console.log('---', data);
   let returnData = [];
   let allNumbers = [];
   data.forEach((d) => {
      allNumbers = [...allNumbers, ...d.numbers];
      // allNumbers.push(d.numbers);
   });

   //pronađi za svaki broj koliko puta se pojavljuje
   const uniqueNumbers = [...new Set(allNumbers)];

   console.log('allNumbers', allNumbers);
   console.log('uniqueNumbers', uniqueNumbers);

   uniqueNumbers.forEach((number) => {
      returnData.push({
         number,
         value: getNumberOfOccurrences(number, allNumbers)
      });
   });
   return returnData.sort((a, b) => a.number - b.number);
};

// redak 1 i 2 mijenjaju vrijednost u Loto+ i Džoker+
const getVrstaName = (arr, rowName) => {
   if (rowName === '2') return 'Loto +';
   if (rowName === '1') return 'Džoker +';
   return arr[0].row_names;
};

const column_names = {
   srbija: 'serbia',
   republikaSrpska: 'rs',
   iznosDobitaka: 'amount'
};
