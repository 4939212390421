import React from 'react';
// import Loto739FAQ from './loto739-o-igri-faq';
// import Loto739Info from './loto739-o-igri-info';
import OigriInfo from './../common/game-o-igri-info';
import GameFAQ from '../common/game-faq';
import { data } from './test-data-bingo-oigri';
import { imgPaths } from './../../store/carousel-store';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { getFAQ } from '../../services/app-service';

const { about, faq } = data;

const BingoOigri = (props) => {
   const { faq } = useSelector((state) => state.landingpageStore);

   return (
      <div className='game-o-igri'>
         <OigriInfo
            data={about}
            // additionClass='bingo-info'
            pathprefix={imgPaths.bingo_sveoigri}
         />
         <GameFAQ data={getFAQ('bingo', faq)} header='Bingo' />
      </div>
   );
};

export default BingoOigri;
