import React from 'react';
import { AiOutlineFilePdf, AiOutlineDownload } from 'react-icons/ai';

const DownloadPDFButton = ({ text, onClick }) => {
   return (
      <div className='download-pdf-button'>
         <button type='button' onClick={onClick}>
            <span>
               <AiOutlineFilePdf />
            </span>
            {text}
            <span>
               <AiOutlineDownload />
            </span>
         </button>
      </div>
   );
};

export default DownloadPDFButton;
