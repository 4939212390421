import React from 'react';
import GameInfoItem from './game-info-item';

const OigriInfo = ({ data, pathprefix, additionClass }) => {
   const className = `game-o-igri-info ${additionClass}`;
   return (
      <div className={className}>
         <div className='game-o-igri-info-content'>
            {data.map((d, i) => (
               <GameInfoItem
                  key={`loto739_${i}`}
                  align={(i + 1) % 2 === 0 ? 'right' : 'left'}
                  header={d.header}
                  text={d.text}
                  img={`${pathprefix}/${d.img}`}
               />
            ))}
         </div>
      </div>
   );
};

export default OigriInfo;
