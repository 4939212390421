import React from 'react';
import NovostiImg from './novosti-img';
import NovostiText from './novosti-text';

const NovostiItem = (props) => {
   const items = [
      {
         img: '',
         date: '12.12.2012',
         header: 'Милионски износ Лото џокера отишао у Приједор',
         text: 'Приједор је у синоћњем извлачењу 13. кола Лота добио милионског добитника у игри Лото џокер, а комбинација срећних бројева која му је донијела срећу и новчани добитак око 1.176.000 КМ је 291317. Срећном добитнику честитамо, а осталим играчима желимо много среће, јер већ у наредном колу Лота имају прилику да освоје милионске износе у играма Лото и Лото плус.'
      },
      {
         img: '',
         date: '12.12.2012',
         header: 'Милионски износ Лото џокера отишао у Приједор',
         text: 'Приједор је у синоћњем извлачењу 13. кола Лота добио милионског добитника у игри Лото џокер, а комбинација срећних бројева која му је донијела срећу и новчани добитак око 1.176.000 КМ је 291317. Срећном добитнику честитамо, а осталим играчима желимо много среће, јер већ у наредном колу Лота имају прилику да освоје милионске износе у играма Лото и Лото плус.'
      },
      {
         img: '',
         date: '12.12.2012',
         header: 'Милионски износ Лото џокера отишао у Приједор',
         text: 'Приједор је у синоћњем извлачењу 13. кола Лота добио милионског добитника у игри Лото џокер, а комбинација срећних бројева која му је донијела срећу и новчани добитак око 1.176.000 КМ је 291317. Срећном добитнику честитамо, а осталим играчима желимо много среће, јер већ у наредном колу Лота имају прилику да освоје милионске износе у играма Лото и Лото плус.'
      }
   ];

   return (
      <>
         {items.map((item) => (
            <div className='novosti-item'>
               <NovostiImg img={item.img} />
               <NovostiText
                  date={item.date}
                  header={item.header}
                  text={item.text}
               />
            </div>
         ))}
      </>
   );
};

export default NovostiItem;
