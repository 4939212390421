import React from 'react';
import { graphData } from './test-data-loto739-statistika-izvucenih-brojeva';
import {
   BarChart,
   Bar,
   Cell,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer
} from 'recharts';

const Graph = ({ data }) => {
   return (
      <div className='graph'>
         <h1>ИЗВУЧЕНИ БРОЈЕВИ</h1>
         {data.length === 0 && (
            <p>Nema izvučenih brojeva unutar odabranih parametara</p>
         )}
         {data.length > 0 && (
            <ResponsiveContainer width='100%' aspect={5}>
               <BarChart data={data}>
                  <CartesianGrid
                     strokeDasharray='0'
                     strokeWidth={0.5}
                     strokeOpacity={0.25}
                  />
                  <XAxis
                     dataKey='number'
                     color='#fff'
                     tick={{ stroke: '#fff', strokeWidth: 0.5 }}
                  />
                  <YAxis tick={{ stroke: '#fff', strokeWidth: 0.5 }} />
                  <Tooltip
                     offset={20}
                     cursor={{ fill: '#fff' }}
                     wrapperStyle={{ fill: 'orange' }}
                     labelStyle={{ fontWeight: 900, color: 'black' }}
                  />
                  <Bar dataKey='value' fill='#b53e7f' />
               </BarChart>
            </ResponsiveContainer>
         )}
      </div>
   );
};

export default Graph;

// https://recharts.org/en-US
