import { paths } from './../routes';

export const appConfig = {
   scrollOffset: 0
};

const isOnscrollOnBodyClassList = () => {
   const cssclass = 'onscroll';
   const bodyClassList = document.body.classList;
   return bodyClassList.contains(cssclass) ? true : false;
};

const addOnscrollCssClassToBody = (add) => {
   const cssclass = 'onscroll';
   const bodyClassList = document.body.classList;

   add ? bodyClassList.add(cssclass) : bodyClassList.remove(cssclass);
};

const handleOnScroll = (dispatch, action) => {
   const addOnScroll = window.pageYOffset > appConfig.scrollOffset;

   if (addOnScroll && isOnscrollOnBodyClassList()) return;
   if (!addOnScroll && !isOnscrollOnBodyClassList()) return;

   if (addOnScroll) {
      dispatch(action(true));
      addOnscrollCssClassToBody(true);
   } else {
      dispatch(action(false));
      addOnscrollCssClassToBody(false);
   }
};

export const enableScroll = (enable) => {
   const html = document.querySelector('html');
   const body = document.querySelector('body');

   if (enable) {
      html.style.overflowX = 'scroll';
      body.style.overflowX = 'scroll';
   } else {
      html.style.overflowX = 'hidden';
      body.style.overflowX = 'hidden';
   }
};

const showCarousel = (path) => {
   const hidePaths = [];
   hidePaths.push(paths.kontaktirajteNas);
   hidePaths.push(paths.novosti);
   return hidePaths.includes(path) ? false : true;
};

export const getBase64Image = (base64data) =>
   `data:image/jpeg;base64,${base64data}`;

export const getCSSbackgroundImage = (base64data) =>
   `url('${getBase64Image(base64data)}')`;

export const aosOptions = {
   offset: 10,
   duration: 200,
   easing: 'ease-in-sine',
   delay: 100
};

export const getFAQ = (gameName, faqArray) =>
   faqArray.filter((x) => x.gameName.toLowerCase() === gameName.toLowerCase());

const exportObj = {
   isOnscrollOnBodyClassList,
   handleOnScroll,
   showCarousel
};

export default exportObj;
