Storage.prototype.setObject = function (key, value) {
   this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
   var value = this.getItem(key);
   return value && JSON.parse(value);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
   var date = new Date(this.valueOf());
   date.setDate(date.getDate() + days);
   return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMonths = function (months) {
   const d = new Date(this.valueOf());
   d.setMonth(d.getMonth() + months);
   return d;
};

// eslint-disable-next-line no-extend-native
Array.prototype.takeFirst = function (count = 1) {
   if (count === 1) return this[0];
   return this.filter((_, index) => index < count);
};

// eslint-disable-next-line no-extend-native
Array.prototype.takeLast = function (count = 1) {
   if (count === 1) return this[this.length - 1];
   return this.filter((_, index) => this.length - index <= count);
};

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function (key) {
   return this.reduce((group, element) => {
      const keyValue = element[key];
      return { ...group, [keyValue]: [...(group[keyValue] ?? []), element] };
   }, {});
};

//   Mapiraj postojeće objekte polja na nove objekte sa zadanim svojstvima(ključevima)
// eslint-disable-next-line no-extend-native
Array.prototype.mapToKeyies = function (keyies) {
   return this.map((item) => {
      const obj = {};
      keyies.forEach((key) => {
         obj[key] = item[key];
      });
      return obj;
   });
};

// eslint-disable-next-line no-extend-native
Array.prototype.sum = function (initialValue = 0) {
   return this.reduce((sum, current) => sum + current, initialValue);
};

// eslint-disable-next-line no-extend-native
Array.prototype.sumByKey = function (key, initialValue = 0) {
   return this.reduce((sum, current) => sum + current[key], initialValue);
};
