import { NavLink } from 'react-router-dom';

const activeLinkClass = (navData) => (navData.isActive ? 'activeLink' : '');

export const getNavLinkElements = (arrLinks) =>
   arrLinks.map((link, i) => (
      <NavLink
         to={link.path}
         className={activeLinkClass}
         key={`${link.path}_${i}`}
      >
         {link.text}
      </NavLink>
   ));
