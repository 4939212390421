import React from 'react';
import TextExpander from './text-expander';
import parseHTML from 'html-react-parser';

const GameFAQ = ({ header = '', subheader = '', data }) => {
   const getHeaderLatin = () => {
      switch (header) {
         case 'loto':
         case 'ЛОТО':
         case 'ЛОТО ПЛУС':
         case 'ЛОТО ЏОКЕР':
            return 'loto';
         case 'bingo':
         case 'Бинго 15/90':
            return 'bingo';
         case 'lotoPlus':
         case 'БИНГО ПЛУС 20/75':
            return 'bingo-plus';
         default:
            return '';
      }
   };

   const baseClassName = 'gameFAQ';
   const className = `${baseClassName} ${getHeaderLatin()}`;
   return (
      <div className={className}>
         <div className='content'>
            {header && <h1>{header}</h1>}
            {subheader && <h1>{subheader}</h1>}

            {data.map((d, i) => (
               <TextExpander label={d.question} key={`te_${i}`}>
                  {parseHTML(d.answer)}
               </TextExpander>
            ))}
         </div>
      </div>
   );
};

export default GameFAQ;
