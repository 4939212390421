import React from 'react';
import logo from '../../img/icon-small.png';
import {
   FaFacebookF,
   FaYoutube,
   FaInstagram,
   FaAngleRight
} from 'react-icons/fa';
import { DiAndroid, DiApple } from 'react-icons/di';
import { NavLink } from 'react-router-dom';
import { paths } from './../../routes';

const Footer = ({ indent = false, style = {} }) => {
   const classBase = 'footer-container';
   const className = indent ? `${classBase} indent` : `${classBase}`;

   return (
      <div className={className} style={style}>
         <div className='footer'>
            <div className='row-top'>
               <div className='column1'>
                  <img src={logo} alt='logo' />
                  <div className='telephones'>
                     <p>(+387) 51-432-700</p>
                     <p>(+387) 51-430-500</p>
                  </div>
                  <p>Вука Карџића 2, 78000, Бања Лука</p>
                  <p>
                     <a href='mailto:info@lutrijars.com'>info@lutrijars.com</a>
                  </p>
                  <div className='social-network-icons'>
                     <FaFacebookF />
                     <FaYoutube />
                     <FaInstagram />
                  </div>
               </div>
               <div className='column2'>
                  <div className='column2-1'>
                     <h2>Лутрија РС</h2>
                     <ul>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />О предузећу
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              За акционаре
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Јавне информације
                           </NavLink>
                        </li>
                     </ul>
                  </div>
                  <div className='column2-2'>
                     <h2>Друштвена одговорност</h2>
                     <ul>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Одговорно приређивање
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Одговорно играње
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Забрана играња
                           </NavLink>
                        </li>
                     </ul>
                  </div>
                  <div className='column2-3'>
                     <h2>За играче</h2>
                     <ul>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Новости
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Правила игара
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Цјеновник
                           </NavLink>
                        </li>
                        <li>
                           <NavLink to={paths.kontaktirajteNas}>
                              <FaAngleRight />
                              Контакт
                           </NavLink>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className='column3'>
                  <p>
                     <DiAndroid /> Android
                  </p>
                  <p>
                     <DiApple /> iOS
                  </p>
               </div>
            </div>
            <div className='row-bottom'>
               <p>&copy; 2022 Лутрија Републике Српске, а.д.</p>
               <p>Играјте одговорно</p>
            </div>
         </div>
      </div>
   );
};

export default Footer;
