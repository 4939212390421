import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import animService from './../../services/animations-service';

const PageContainer = ({
   children,
   className,
   style,
   pageAnimation = animService.default
}) => {
   const classAddition = className ? ` ${className}` : '';
   const _className = `page${classAddition}`;

   useEffect(() => {
      window.scrollTo(0, 0);

      const scrollTop = Math.max(
         window.pageYOffset,
         document.documentElement.scrollTop,
         document.body.scrollTop
      );
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   window.onscroll = (e) => {
      // console.log(window.pageYOffset);
   };

   return (
      <motion.div
         className={_className}
         style={style ? style : ''}
         variants={pageAnimation}
         initial='hidden'
         animate='visible'
         exit='exit'
      >
         {children}
      </motion.div>
   );
};

export default PageContainer;
