import http from '../services/http';
import config from '../config/api-config.json';

const initialState = {
   lotoReport: [],
   lotoDetailedReport: [],
   lotoNextRoundFundsReport: [],
   lotoWinnersReport: [],
   loaded: false
};

export const loadLotoData = () => async (dispatch, getState) => {
   try {
      const response = await http.get(config.lotodata);

      const {
         lotoReport,
         lotoDetailedReport,
         lotoNextRoundFundsReport,
         lotoWinnersReport
      } = response;

      const payload = {
         lotoReport: !lotoReport ? [] : lotoReport,
         lotoDetailedReport: !lotoDetailedReport ? [] : lotoDetailedReport,
         lotoNextRoundFundsReport: !lotoNextRoundFundsReport
            ? []
            : lotoNextRoundFundsReport,
         lotoWinnersReport: !lotoWinnersReport ? [] : lotoWinnersReport,
         loaded: true
      };

      dispatch({ type: 'LOAD_LOTO_DATA', payload });
   } catch (ex) {
      console.log('error-LOAD_LOTO_DATA', ex);
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'LOAD_LOTO_DATA':
         const {
            lotoReport,
            lotoDetailedReport,
            lotoNextRoundFundsReport,
            lotoWinnersReport,
            loaded
         } = action.payload;
         return {
            ...state,
            lotoReport,
            lotoDetailedReport,
            lotoNextRoundFundsReport,
            lotoWinnersReport,
            loaded
         };
      default:
         return state;
   }
}
