export const template = {
   about: [
      {
         header: '',
         text: '',
         img: ''
      }
   ],
   faq: [
      {
         header: '',
         qa: [
            {
               label: '',
               content: ''
            }
         ]
      }
   ]
};

export const data = {
   about: [
      {
         header:
            'Добро дошли на једну од најомиљених игара на срећу у којој учествује чак 80% становништва Републике Српске.',
         text: 'Са срећкама су се људи дружили још у римско доба, а српска државна лутрија стартовала је авне 1980. године. Назив лутрија потиче од енглеске ријечи lottery, што значи жријебати, извлачити... Ријетки су они који никад нису испунили свој листић, али велики број људи игра свако коло јер се нада да је баш његова комбинација добитна. <br><br> Ко не игра и не добија. Међутим, у игри је 15.790.000 комбинација и у воду падају све приче о могућим намјештаљкама - да су лоптице намагнетисане, да утиче струјање ваздуха и слично. Лото се не може намјестити. То је чиста срећа.',
         img: `loto-kugle.png`
      },
      {
         header: 'Како могу играти Лото?',
         text: 'ЛОТО 7/39 је најпопуларнија игра Лутрије Републике Српске. Представља бројчану игру на срећу са изузетно великом премијом, популарном „седмицом“, која достиже огромне износе. Тип игре је 7/39, што значи да играч бира своју комбинацију од 7 бројева из групе бројева од 1 до 39. Играч може ЛОТО одиграти на два начина: попуњавањем помоћног листића и његовом предајом на уплатном мјесту Лутрије РС, након чега добија потврду о уплати, као једини валидан доказ о учествовању у  ређеном колу. Други начин је путем интернета у којој играчи посредством интернета врше избор и уплату комбинација, те наплату потенцијалних добитака.',
         img: `loto-bubanj.png`
      }
   ],
   faq: [
      {
         header: 'ЛОТО',
         qa: [
            {
               label: 'Како да сазнам је ли “седмица” моја?',
               content: `<h2>ПРОВЈЕРИТЕ НА НАШОЈ ВЕБ СТРАНИЦИ</h2>
               <p>Увијек освјежени резултати свих игара Лутрије Републике Српске на вашем омиљеном уређају.</p>
               <br />
               <h2>ПОСЈЕТИТЕ ПРОДАЈНО МЈЕСТО ЛУТРИЈЕ РЕПУБЛИКЕ СРПСКЕ</h2>
               <p>Пронађите најближе продајно мјесто</p>
               <br />
               <h2>НАЗОВИТЕ 051 432 700 или 051 430 500</h2>
               <p>На секретарици добијате све резултате Лутрије Републике Српске.</p>
               <br />
               <h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2>
               <p>Резултате извлачења можете добити и на својој адреси е - поште.</p>`
            },
            {
               label: 'Шта значи играти систем у Лоту?',
               content: `<h2>ПОСЈЕТИТЕ ПРОДАЈНО МЈЕСТО ЛУТРИЈЕ РЕПУБЛИКЕ СРПСКЕ</h2>
                <p>Пронађите најближе продајно мјесто</p>
                <br />
                <h2>НАЗОВИТЕ 051 432 700 или 051 430 500</h2>
                <p>
                   На секретарици добијате све резултате Лутрије Републике
                   Српске.
                </p>`
            },
            {
               label: 'Колики је Лото фонд добитак?',
               content: `<h2>ПРОВЈЕРИТЕ НА НАШОЈ ВЕБ СТРАНИЦИ</h2>
                <p>
                   Увијек освјежени резултати свих игара Лутрије Републике Српске
                   на вашем омиљеном уређају.
                </p>
                <br />
                <h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2>
                <p>
                   Резултате извлачења можете добити и на својој адреси е -
                   поште.
                </p>`
            },
            {
               label: 'Колика је цијена игре Лото 7/39?',
               content: `<h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2>
                <p>
                   Резултате извлачења можете добити и на својој адреси е -
                   поште.
                </p>`
            },
            {
               label: 'Који су рекордни добици у Лоту?',
               content: ``
            },
            {
               label: 'Колико пута могу платити за Лото?',
               content: ``
            },
            {
               label: 'Када могу преузети Лото добитак?',
               content: ``
            }
         ]
      },
      {
         header: 'ЛОТО ПЛУС',
         qa: [
            {
               label: 'Шта је Лото Плус?',
               content: `<h2>Шта је Лото Плус</h2>
                <p>Лото плус је опциона игра у оквиру извлачења игре лото 7/39.</p>`
            },
            {
               label: 'Како се игра Лото Плус?',
               content: `<h2>Како се игра Лото Плус</h2>
                <p>Уколико oдиграте Лото +, свака ваша комбинација учествује у додатном извлачењу 7 бројева за добитак „СЕДМИЦА“.У додатном извлачењу постоји само добитак са 7 погодака. Извлаче се бројеви од 1 до 39, а ви учествујете са истим комбинацијама као у првом извлачењу.
                </p>`
            },
            {
               label: 'Колика је цијена по Лото Плус комбинацији?',
               content: ``
            }
         ]
      },
      {
         header: 'ЛОТО ЏОКЕР',
         qa: [
            {
               label: 'Шта је Лото Џокер?',
               content: `<h2>Шта је Лото Џокер?</h2>
                <p>Лото џокер је опциона игра у оквиру извлачења игре лото 7/39.</p>`
            },
            {
               label: 'Како се игра Лото џокер?',
               content: `<h2>Како се игра Лото Плус</h2>
                <p>Уколико играч жели да одигра ову игру, на помоћном листићу Лото 7/39 у доњем десном углу налази се Џокер број а поред њега квадратићи са опцијом 1,2,5,10, 50 и НЕ, неопходно је прекрижити квадратић са једним од бројева (нпр. 2, тада су на потврди о уплати исписана 2 Џокер броја). Ова игра има само један добитак а то је
                свих шест погођених бројева у комбинацији тј. уколико је добитна комбинација 503340 само тим редослиједом је могуће остварити добитак.</p>`
            },
            {
               label: 'Колика је цијена Лото Џокер комбинације?',
               content: ``
            }
         ]
      }
   ]
};
