import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

const SelectTransparent = ({
   name,
   options,
   text = 'text',
   val = 'value',
   value,
   onChange
}) => {
   const [state, setState] = useState({});

   useEffect(() => {});

   return (
      <select
         name={name}
         id={name}
         className='select-transparent'
         onChange={({ target: ddl }) => {
            onChange({ text: ddl.selectedOptions[0].text, value: +ddl.value });
         }}
         value={value}
      >
         {options.map((o) => (
            <option key={uuid()} value={o[val]}>
               {o[text]}
            </option>
         ))}
      </select>
   );
};

export default SelectTransparent;
