import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
   let location = useLocation();

   const protectedUvijet = true;
   if (protectedUvijet)
      return (
         <Navigate to='/not-found-page' state={{ from: location }} replace />
      );
   return children;
};

export default ProtectedRoute;
