import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';

const store = () => {
   const composeEnhancer =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

   const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));
   return store;
};

export default store;
