import React from 'react';
import GamePanelButton from '../landingpage/games/game-panel-button';

const InputTextWithCustomButton = ({
   name = '',
   id = '',
   btn = <GamePanelButton text='Напријед' transparent={false} />,
   placeholder = 'Унесите своју адресу е - поште',
   className = '',
   style = {}
}) => {
   return (
      <div className={`input-with-button ${className}`} style={style}>
         <input type='text' name={name} id={id} placeholder={placeholder} />
         {btn}
      </div>
   );
};

export default InputTextWithCustomButton;
