export const template = {
   about: [
      {
         header: '',
         text: '',
         img: ''
      }
   ],
   faq: [
      {
         header: '',
         qa: [
            {
               label: '',
               content: ''
            }
         ]
      }
   ]
};

export const data = {
   about: [
      {
         header:
            'Бинго је популарна игра која се може играти за новац и награде.',
         text: 'Историја игре може се пратити од 1530. године, до италијанске лутрије, која се и даље игра сваке суботе у Италији. Из Италије, игра је уведена у Француску крајем 1770-их, игра која се играла међу богатим Французима. Немци су такође играли верзију игре 1800-их, али су је користили као дјечију игру како би помогли ученицима да науче математику, правопис и историју. У САД-у се бинго првобитно звао „беано“. Викали су „беано“ ако су побједили. Њујоршки продавач играчака Едвин С. Лове преименовао га је у „бинго“ након што је чуо да је неко случајно викао „бинго“ уместо „беано“. У Бингу има поприлично врста и варијација, али главне су двије које се играју по броју коришћених бројева - бинго за 90 и 75 лоптица.',
         img: 'bingo1.png'
      },
      {
         header: 'Како могу играти Бинго?',
         text: 'БИНГО 15/90 је пупуларна игра Лутрије Републике Српске. Бинго добитак се добија када играч усклади бројеве на својој картици са онима које се насумично извлаче из бубња. Прва особа која је заокружила својих 15. бројева у комбинацији добија награду. У игри ТВ Бинго са сваким помоћним листићем на располагању су три опције: Можете одиграти и једну и другу игру: три „БИНГО“ комбинације и двије „БИНГО ПЛУС“ комбинације или можете одиграти само три „БИНГО“ комбинације или можете одиграти само двије „БИНГО ПЛУС“ комбинације.',
         img: 'bingo2.png'
      }
   ],
   faq: [
      {
         header: 'Бинго 15/90',
         qa: [
            {
               label: 'Бинго 15/90',
               content: `<h2>ПРОВЈЕРИТЕ НА НАШОЈ ВЕБ СТРАНИЦИ</h2><p>Увијек освјежени резултати свих игара Лутрије Републике Српске на вашем омиљеном уређају.</p><br /><h2>ПОСЈЕТИТЕ ПРОДАЈНО МЈЕСТО ЛУТРИЈЕ РЕПУБЛИКЕ СРПСКЕ</h2><p>Пронађите најближе продајно мјесто</p><br /><h2>НАЗОВИТЕ 051 432 700 или 051 430 500</h2><p>На секретарици добијате све резултате Лутрије Републике Српске.</p><br /><h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2><p>Резултате извлачења можете добити и на својој адреси е - поште.</p>`
            },
            {
               label: 'Шта је Бинго 15/90?',
               content: `<h2>ПОСЈЕТИТЕ ПРОДАЈНО МЈЕСТО ЛУТРИЈЕ РЕПУБЛИКЕ СРПСКЕ</h2>
         <p>Пронађите најближе продајно мјесто</p>
         <br />
         <h2>НАЗОВИТЕ 051 432 700 или 051 430 500</h2>
         <p>
            На секретарици добијате све резултате Лутрије Републике
            Српске.
         </p>`
            },
            {
               label: 'Како се игра Бинго 15/90?',
               content: `<h2>ПРОВЈЕРИТЕ НА НАШОЈ ВЕБ СТРАНИЦИ</h2>
         <p>
            Увијек освјежени резултати свих игара Лутрије Републике Српске
            на вашем омиљеном уређају.
         </p>
         <br />
         <h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2>
         <p>
            Резултате извлачења можете добити и на својој адреси е -
            поште.
         </p>`
            },
            {
               label: 'Колика је цијена игре Бинго 15/90?',
               content: `<h2>ОБАВЈЕШТЕЊЕ О НАРУЏБИ</h2>
         <p>
            Резултате извлачења можете добити и на својој адреси е -
            поште.
         </p>`
            },
            {
               label: 'Који су добици у игри Бинго 15/90?',
               content: `loremipsum`
            },
            {
               label: 'Када могу преузети Бинго добитак?',
               content: `loremipsum`
            },
            {
               label: 'Снимци извлачења',
               content: `loremipsum`
            }
         ]
      },
      {
         header: 'БИНГО ПЛУС 20/75',
         qa: [
            {
               label: 'Шта је БИНГО Плус?',
               content:
                  '<p>Бинго плус је додатна игра у оквиру извлачења игре Бинго 15/90 у којој имамо добитке Бинго плус, Суперцентар, Суперпрстен, Прстен, Центар и добитак Коцкица.</p>'
            }
         ]
      }
   ]
};
