import React from 'react';

const TableIznosDobitakaLoto739 = ({ data }) => {
   return (
      <div className='loto739-iznos-dobitaka'>
         <table>
            <thead>
               <tr>
                  <th>Врста</th>
                  <th>Добици Србија</th>
                  <th>Добици РС</th>
                  <th>Износ добитка</th>
               </tr>
            </thead>
            <tbody>
               {data.map((d, i) => (
                  <tr key={`Loto739IznosDobitaka${i}`}>
                     <td>{d.vrsta}</td>
                     <td>{d.dobiciSrbija}</td>
                     <td>{d.dobiciRS}</td>
                     <td>{d.iznosDobitaka}</td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default TableIznosDobitakaLoto739;
