import http from '../services/http';
import config from '../config/api-config.json';

const initialState = {
   bingoReport: [],
   bingoDetailedReport: [],
   bingoNextRoundFundReport: [],
   bingoFinalReport: [],
   loaded: false
};

export const loadBingoData = () => async (dispatch, getState) => {
   try {
      const response = await http.get(config.bingodata);

      const {
         bingoReport,
         bingoDetailedReport,
         bingoNextRoundFundReport,
         bingoFinalReport
      } = response;

      const payload = {
         bingoReport,
         bingoDetailedReport,
         bingoNextRoundFundReport,
         bingoFinalReport,
         loaded: true
      };

      dispatch({ type: 'LOAD_BINGO_DATA', payload });
   } catch (ex) {
      console.log('error-LOAD_BINGO_DATA', ex);
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'LOAD_BINGO_DATA':
         const {
            bingoReport,
            bingoDetailedReport,
            bingoNextRoundFundReport,
            bingoFinalReport,
            loaded
         } = action.payload;
         return {
            ...state,
            bingoReport,
            bingoDetailedReport,
            bingoNextRoundFundReport,
            bingoFinalReport,
            loaded
         };
      default:
         return state;
   }
}
