import axios from 'axios';
import api from '../config/api-config.json';
// import { history } from './../index';

const baseURL = api.baseURL;
const token = 'NR2XI4TJNJQSAUSTEBIGC3TFNQQEI2L2MFVG4===';

axios.defaults.baseURL = `${baseURL}`;
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

axios.interceptors.response.use(
   (response) => {
      // console.log('response', response);
      const { data } = response;
      return data;
   },
   (error) => {
      //expected error - in 400 range
      // eslint-disable-next-line
      const expectedError =
         error.response && error.response.status >= 400 && error.status < 500;

      if (axios.isCancel(error)) {
         console.log('axios request cancelled', error.response);
      }

      if (error.response) {
         const { status, data, config } = error.response;
         switch (status) {
            case 400:
               console.log('400:bad request');
               break;
            case 401:
               console.log('401:unauthorized user');
               break;
            case 404:
               // history.push(`/not-found/${encodeURIComponent(config.url)}`);
               break;
            case 500:
               console.log('500:internal server error');
               break;
            case 503:
               console.log('503:Service Unavailable');
               break;
            default:
               break;
         }
      }
      // toastAxiosError(error.toJSON());
      //to pass control to catch() block we need to return a rejected Promise with error included
      return Promise.reject(error);
   }
);

const exportObj = {
   get: axios.get,
   post: axios.post,
   put: axios.put,
   delete: axios.delete,
   all: axios.all
};
export default exportObj;

// https://stackoverflow.com/questions/52737078/how-can-you-use-axios-interceptors
