import React, { useState } from 'react';
import FormInput from '../common/form/form-input';
import FormTextarea from '../common/form/form-textarea';
import GamePanelButton from './../landingpage/games/game-panel-button';
import schemas from '../../validation-schemas/schemas';
import { validateForm } from '../common/form/form-input-service';

const ContactForm = (props) => {
   const [state, setState] = useState({
      validationData: {
         ime: '',
         prezime: '',
         email: '',
         predmet: '',
         poruka: ''
      },
      errors: {}
   });

   const doSubmit = () => {
      console.log('doSubmit', state.validationData);
   };

   const additionalFn = () => {
      console.log('additionalFN', state.validationData.ime);
   };

   return (
      <form
         onSubmit={(e) => {
            e.preventDefault();
            doSubmit();
         }}
      >
         <h2>Пошаљите поруку</h2>
         <div className='form-input-elements'>
            <FormInput
               label='Ime'
               name='ime'
               formState={[state, setState]}
               schema={schemas.ContactUs}
               onChange={additionalFn}
               debounceDelay={1000}
               placeholder='Име'
            />
            <FormInput
               name='prezime'
               formState={[state, setState]}
               schema={schemas.ContactUs}
               placeholder='Презиме'
            />
            <FormInput
               name='email'
               formState={[state, setState]}
               schema={schemas.ContactUs}
               placeholder='Е-маил'
            />
            <FormInput
               name='predmet'
               formState={[state, setState]}
               schema={schemas.ContactUs}
               placeholder='Предмет поруке'
            />
            <FormTextarea
               name='poruka'
               placeholder='Ваша порука'
               formState={[state, setState]}
               schema={schemas.ContactUs}
               onChange={additionalFn}
               debounceDelay={100}
            />

            <GamePanelButton
               text='Пошаљи'
               style={{ marginLeft: '0.5rem' }}
               disabled={validateForm(state, schemas.ContactUs)}
               onClick={doSubmit}
            />
         </div>
      </form>
   );
};

export default ContactForm;
