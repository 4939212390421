import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import { getBase64Image } from './../../services/app-service';
import { paths } from './../../routes';

// https://www.npmjs.com/package/react-responsive-carousel

const ImageCarousel = ({ path }) => {
   let images = [];
   const state = useSelector((state) => state.carouselStore);

   switch (path) {
      case paths.landingpage:
      case paths.loto7_39:
      case `${paths.loto7_39}/${paths.loto7_39_nested.oigri}`:
      case `${paths.loto7_39}/${paths.loto7_39_nested.rezultati}`:
      case `${paths.loto7_39}/${paths.loto7_39_nested.statistika}`:
         images = state.main;
         break;

      case paths.bingo:
      case `${paths.bingo}/${paths.bingo_nested.oigri}`:
      case `${paths.bingo}/${paths.bingo_nested.rezultati}`:
         images = state.bingo;
         break;

      default:
         break;
   }
   if (images) images = images.map((i) => i.image);

   return (
      <Carousel
         autoPlay={true}
         infiniteLoop={true}
         interval={5000}
         transitionTime={500}
         stopOnHover={false}
         showArrows={false}
         showStatus={false}
         showIndicators={false}
         showThumbs={false}
         axis='vertical'
         dynamicHeight={true}
      >
         {images &&
            images.length > 0 &&
            images.map((url, i) => (
               <div key={`imgCarousel${i}`}>
                  <img src={getBase64Image(url)} alt={`slider${i}`} />
               </div>
            ))}
      </Carousel>
   );
};

export default ImageCarousel;
