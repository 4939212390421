import React from 'react';
import Panel from './../common/panel';
import { panelSizes } from './../../services/panel-service';
import { CgFacebook, CgInstagram } from 'react-icons/cg';
import { BsYoutube } from 'react-icons/bs';

const NovostiImg = (props) => {
   return (
      <div className='novosti-img'>
         <Panel size={panelSizes.novosti}></Panel>
         <div className='social-network-icons-container'>
            <div className='social-network-icons'>
               <CgFacebook style={{ color: '#0572e6' }} />
               <CgInstagram />
               <BsYoutube style={{ color: '#f00' }} />
            </div>
         </div>
      </div>
   );
};

export default NovostiImg;
