import React from 'react';
import { ImSpinner9 } from 'react-icons/im';

const LoadingInformation = (props) => {
   return (
      <div className='loading-information'>
         <ImSpinner9 />
      </div>
   );
};

export default LoadingInformation;
