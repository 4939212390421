import React from 'react';
import { imgPaths } from './../../store/carousel-store';

const Stopwatch = (props) => {
   const days = 2;
   const [HH, MM, SS] = [0, 0, 0];

   return (
      <div className='stopwatch'>
         {/* <BiStopwatch className='stopwatch-icon' /> */}
         <img
            src={`${imgPaths.landingpage}/stopwatch-icon.png`}
            alt='stopwatch-icon'
         />
         {`${days}Д : ${HH}С : ${MM}М : ${SS}С`}
      </div>
   );
};

export default Stopwatch;
