import React from 'react';
import Footer from '../components/common/footer';
import ContactUs from './../components/contact/contact-us';

const ContactPage = (props) => {
   return (
      <>
         <div className='contact-page'>
            <h1>Контактирајте нас</h1>
            <ContactUs />
         </div>
         <Footer />
      </>
   );
};

export default ContactPage;
