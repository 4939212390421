import React from 'react';
import Stopwatch from '../../common/stopwatch';
import GamePanelButton from './game-panel-button';
import { imgPaths } from './../../../store/carousel-store';
import {
   getBase64Image,
   getCSSbackgroundImage
} from '../../../services/app-service';

// Ako je showAsColumnPanel=false prikazuje se fullrow panela
const GamePanel = ({
   logo = '',
   text,
   title = '',
   payment = [],
   showAsColumnPanel = true,
   backgroundImage = '',
   onAboutClick,
   onResultsClick,
   showStopwatch = false,
   showBtnAbout = false,
   showBtnResults = false,
   showBtnPlay = true
}) => {
   const backgroundImagePath = `${
      process.env.PUBLIC_URL + imgPaths.landingpage
   }`;

   return (
      <div
         className='game-panel'
         style={{
            background: getCSSbackgroundImage(backgroundImage),
            backgroundSize: 'cover',
            backgroundPosition: 'center'
         }}
      >
         {showAsColumnPanel && (
            <div className='gp-header'>
               <div className='gp-logo'>
                  {logo && <img src={getBase64Image(logo)} alt='game logo' />}
               </div>
               <div className='gp-play-btn'>
                  {showBtnPlay && (
                     <GamePanelButton
                        text='ИГРАЈ'
                        transparent={false}
                        onClick={onAboutClick}
                     />
                  )}
               </div>
            </div>
         )}

         {showAsColumnPanel && text && (
            <>
               <div className='gp-info-header'>{text.header}</div>

               {text.data.map((d, i) => (
                  <div className='gp-info-container' key={d.game}>
                     <div className='gp-icon'>
                        <img src={getBase64Image(d.icon)} alt='icon' />
                     </div>
                     <div className='gp-info'>
                        <div className='gp-title'>{d.game}</div>
                        <div className='gp-amount'>{d.amount}</div>
                     </div>
                  </div>
               ))}
            </>
         )}

         {!showAsColumnPanel && (
            <div className='fullrow-container'>
               <div className='left'>{title}</div>
               <div className='right'></div>
            </div>
         )}

         <div className='buttons-container'>
            <div className='stopwatch-container'>
               {showStopwatch && <Stopwatch />}
            </div>
            <div className='btns'>
               {showBtnAbout && (
                  <GamePanelButton text='Све о игри' onClick={onAboutClick} />
               )}
               {showBtnResults && (
                  <GamePanelButton text='Резултати' onClick={onResultsClick} />
               )}
               {/* {showBtnPlay && <GamePanelButton text='Играти' />} */}
            </div>
         </div>
      </div>
   );
};

export default GamePanel;
