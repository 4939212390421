import React from 'react';
import NotFound from './../components/common/not-found';

const NotFoundPage = (props) => {
   return (
      <>
         <h1>not-found-page</h1>
         <NotFound />
      </>
   );
};

export default NotFoundPage;
