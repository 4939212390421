import React from 'react';
import { AiOutlineRotateRight } from 'react-icons/ai';

const LandscapeInformation = (props) => {
   return (
      <div className='landscape-information prevent-text-select prevent-click'>
         <p>Use only in portrait mode</p>
         <AiOutlineRotateRight />
      </div>
   );
};

export default LandscapeInformation;
