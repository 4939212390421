import React from 'react';
import { isMobile } from 'react-device-detect';

const GoogleMaps = ({ width = isMobile ? '100%' : '95%', height = '460' }) => {
   return (
      <iframe
         src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.2542956984717!2d17.18882971573605!3d44.77562078714393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475e031ca829cb5f%3A0xc076b0cc60904299!2sVuka%20Karad%C5%BEi%C4%87a%2C%20Banja%20Luka%2078000%2C%20Bosnia%20and%20Herzegovina!5e0!3m2!1sen!2shr!4v1650535073908!5m2!1sen!2shr'
         width={width}
         height={height}
         style={{ border: 0 }}
         allowFullScreen=''
         loading='lazy'
         referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
   );
};

export default GoogleMaps;
