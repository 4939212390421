import React, { useEffect, useState } from 'react';
import NewsPanel from './news-panel';
import { landingpage2RowColumnDefinition as colDef } from '../../../services/image-path-service';
import Panel from '../../common/panel';
import { panelSizes } from './../../../services/panel-service';
import { testdata } from '../games/test-data';
import { imgPaths } from './../../../store/carousel-store';

const NewsContainer = ({ data: newsdata }) => {
   const backgroundImagePath = `${
      process.env.PUBLIC_URL + imgPaths.landingpage
   }`;

   if (!newsdata.length) return null;

   return (
      <>
         {newsdata.map((data, i) => (
            <div className='row' key={`newscontainer-${i}`}>
               {data.map((d, j) => (
                  <div className={colDef} key={`newscontainerrow-${j}`}>
                     <Panel size={panelSizes.newsSmallCell}>
                        <NewsPanel
                           date={d.date}
                           img={d.img}
                           title={d.title}
                           text={d.text}
                        />
                     </Panel>
                  </div>
               ))}
            </div>
         ))}
      </>
   );
};

export default NewsContainer;
