import axios from 'axios';
import http from '../services/http';
import config from '../config/api-config.json';

const initialState = {
   rowdata: [],
   fullrowdata: [],
   newsdata: [],
   faq: [],
   loaded: false
};

export const loadLandingpageData = () => async (dispatch, getState) => {
   try {
      const gerRowdata = () => http.get(config.landingpageRowdata);
      const getFullrowdata = () => http.get(config.landingpageFullrowdata);
      const getNewsdata = () => http.get(config.landingpageNews);
      const getFAQ = () => http.get(config.faqdata);

      Promise.all([
         gerRowdata(),
         getFullrowdata(),
         getNewsdata(),
         getFAQ()
      ]).then((results) => {
         console.log('loadLandingpageData', results);
         const [rowdata, fullrowdata, newsdata, faq] = results;

         const payload = {
            rowdata,
            fullrowdata,
            newsdata,
            faq,
            loaded: true
         };

         dispatch({ type: 'LOAD_LANDINGPAGE_DATA', payload });
      });
   } catch (ex) {
      console.log('error-LOAD_LANDINGPAGE_DATA', ex);
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case 'LOAD_LANDINGPAGE_DATA':
         const { rowdata, fullrowdata, newsdata, faq, loaded } = action.payload;
         return { ...state, rowdata, fullrowdata, newsdata, faq, loaded };
      default:
         return state;
   }
}
