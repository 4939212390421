import Loto739Oigri from './components/loto-7-39/loto739-o-igri';
import Loto739Rezultati from './components/loto-7-39/loto739-rezultati';
import Loto739Statistika from './components/loto-7-39/loto739-statistika';
import Second from './components/templates/components/second';
import LandingPage from './pages/landing-page';
import Loto739Page from './pages/loto-7-39-page';
import NotFoundPage from './pages/not-found-page';
import animService from './services/animations-service';
import ContactPage from './pages/contact-page';
import NovostiPage from './pages/novosti-page';
import BingoPage from './pages/bingo-page';
import BingoOigri from './components/bingo/bingo-o-igri';
import BingoRezultati from './components/bingo/bingo-rezultati';

const asterisk = '/*';

export const paths = {
   landingpage: '/landingpage',
   loto7_39: '/loto7-39',
   loto7_39_nested: {
      oigri: 'o-igri',
      rezultati: 'rezultati',
      statistika: 'statistika'
   },
   loto4_60: '/loto4-60',
   bingo: '/bingo',
   bingo_nested: {
      oigri: 'o-igri',
      rezultati: 'rezultati',
      statistika: 'statistika'
   },
   grebe: '/grebe',
   horoskop: '/horoskop',
   prodajnaMjesta: '/prodajna-mjesta',
   kontaktirajteNas: '/kontaktirajte-nas',
   rezultati: '/rezultati',
   novosti: '/novosti',
   register: '/register',
   prijava: '/prijava',
   notFoundPage: '/not-found-page'
};
/*
    [className] opcionalno dodajem na <Page/> konteiner ako treba
    utjecati na izgled cijelog Page-a na pojedinim stranicama

    [pageAnimation] ukoliko želim custom animaciju
*/
export const pageRoutes = [
   {
      path: paths.landingpage,
      element: <LandingPage />,
      wrapperClassName: 'landingpage-wrapper',
      protected: false
   },
   {
      path: `${paths.loto7_39}${asterisk}`,
      element: <Loto739Page />,
      protected: false,
      pageAnimation: animService.opacity,
      nested: [
         {
            path: paths.loto7_39_nested.oigri,
            element: <Loto739Oigri />
         },
         {
            path: paths.loto7_39_nested.rezultati,
            element: <Loto739Rezultati />
         },
         {
            path: paths.loto7_39_nested.statistika,
            element: <Loto739Statistika />
         }
      ]
   },
   {
      path: `${paths.bingo}${asterisk}`,
      element: <BingoPage />,
      protected: false,
      pageAnimation: animService.opacity,
      nested: [
         {
            path: paths.bingo_nested.oigri,
            element: <BingoOigri />
         },
         {
            path: paths.bingo_nested.rezultati,
            element: <BingoRezultati />
         },
         {
            path: paths.bingo_nested.statistika,
            element: <Loto739Statistika />
         }
      ]
   },
   {
      path: paths.kontaktirajteNas,
      element: <ContactPage />,
      wrapperClassName: 'contact-page-wrapper',
      protected: false
   },
   {
      path: paths.novosti,
      element: <NovostiPage />,
      protected: false
   },
   {
      path: paths.grebe,
      element: <Second />,
      protected: true
   },
   {
      path: paths.horoskop,
      element: <Second />,
      protected: true
   },
   {
      path: paths.prodajnaMjesta,
      element: <Second />,
      protected: true
   },
   {
      path: paths.rezultati,
      element: <Second />,
      protected: true
   },
   {
      path: paths.register,
      element: <Second />,
      protected: true
   },
   {
      path: paths.prijava,
      element: <Second />,
      protected: true
   },
   {
      path: paths.notFoundPage,
      element: <NotFoundPage />,
      protected: false
   }
];

export const topNavbarLinks = [
   {
      text: 'Продајна мјеста',
      path: paths.prodajnaMjesta
   },
   {
      text: 'Резултати',
      path: paths.rezultati
   },
   {
      text: 'Региструј се',
      path: paths.register
   },
   {
      text: 'Пријави се',
      path: paths.prijava
   }
];

export const bottomNavbarLinks = [
   {
      text: 'ЛОТО 7/39',
      path: `${paths.loto7_39}/${paths.loto7_39_nested.oigri}`
   },
   {
      text: 'БИНГО',
      path: `${paths.bingo}/${paths.bingo_nested.oigri}`
   },
   {
      text: 'ГРЕБЕ',
      path: paths.grebe
   },
   {
      text: 'ХОРОСКОП',
      path: paths.horoskop
   },
   {
      text: 'ЛОТО 4/60',
      path: paths.novosti
   }
];

export const loto739NavbarLinks = [
   {
      text: 'Све о игри',
      path: paths.loto7_39_nested.oigri
   },
   {
      text: 'Резултати',
      path: paths.loto7_39_nested.rezultati
   },
   {
      text: 'Статистика',
      path: paths.loto7_39_nested.statistika
   }
];

export const bingoNavbarLinks = [
   {
      text: 'Све о игри',
      path: paths.bingo_nested.oigri
   },
   {
      text: 'Резултати',
      path: paths.bingo_nested.rezultati
   }
];
