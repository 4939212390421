import React from 'react';
import Footer from '../components/common/footer';
import ContactUs from '../components/contact/contact-us';
import NewsletterContainer from '../components/landingpage/newsletter/newsletter-container';
import Novosti from '../components/novosti/novosti';

const NovostiPage = (props) => {
   return (
      <>
         <div className='novosti-page'>
            <h1>Новости</h1>
            <div className='novosti-container'>
               <Novosti />
               <NewsletterContainer />
            </div>
         </div>
         <Footer indent={true} />
      </>
   );
};

export default NovostiPage;
