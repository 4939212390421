import React from 'react';
import { isMobile } from 'react-device-detect';
import { getCSSbackgroundImage } from './../../../services/app-service';
import parseHTML from 'html-react-parser';

const NewsPanel = ({ title = '', text = '', date = '', img = null }) => {
   const className = `news-panel `;

   return (
      <div className={className}>
         <div className='date'>{date}</div>
         <div className='text-container'>
            <div className='title'>{parseHTML(title)}</div>
            <div className='text'>{parseHTML(text)}</div>
            <button type='button' className='news-link'>
               Цијели текст
            </button>
         </div>
         <div
            className='img-container'
            style={{ background: getCSSbackgroundImage(img) }}
         ></div>
         {/* {img && <img src={img} alt='news thumbnail' />} */}
      </div>
   );
};

export default NewsPanel;
