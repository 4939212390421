import React from 'react';
import Footer from '../components/common/footer';
import GamesContainer from '../components/landingpage/games/games-container';
import NewsContainer from '../components/landingpage/news/news-container';
import NewsletterContainer from './../components/landingpage/newsletter/newsletter-container';
import { useSelector } from 'react-redux';
import LoadingInformation from '../components/common/loading-information';
import { motion } from 'framer-motion';

const LandingPage = (props) => {
   const { rowdata, fullrowdata, newsdata, loaded } = useSelector(
      (state) => state.landingpageStore
   );

   return (
      <>
         <div className='landing-page'>
            {!loaded && <LoadingInformation />}
            {loaded && (
               <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <GamesContainer data={[rowdata, fullrowdata]} />
                  <NewsContainer data={newsdata} />
                  <NewsletterContainer />
               </motion.div>
            )}
         </div>
         {loaded && <Footer indent={true} />}
      </>
   );
};

export default LandingPage;
