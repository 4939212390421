import React from 'react';
import ContactBanner from './contact-banner';
import { BsEnvelope, BsTelephone, BsFlag } from 'react-icons/bs';
import GoogleMaps from './contact-google-maps';
import ContactForm from './contact-form';

const ContactUs = (props) => {
   return (
      <div className='contact-us'>
         <p>
            На нашим страницама нисте пронашли одговоре које сте тражили?
            Уколико имате било какав упит, коментар или сугестију молимо Вас да
            нас контактирате. Ми ћемо се потрудити да Вам одговоримо што је
            прије могуће.
         </p>
         <div className='banners'>
            <ContactBanner
               title='Телефон'
               content={
                  <>
                     <p>+387 51 432700</p>
                     <p>+387 51 432 500</p>
                  </>
               }
               icon={<BsTelephone />}
            />
            <ContactBanner
               title='Е-маил'
               content={
                  <a href='mailto:info@lutrijars.com'>info@lutrijars.com</a>
               }
               icon={<BsEnvelope />}
            />
            <ContactBanner
               title='Сједиште предузећа'
               content={
                  <>
                     <p>Вука Караџића 2</p>
                     <p>Бања Лука 78000</p>
                  </>
               }
               icon={<BsFlag />}
            />
         </div>
         <div className='contact-form'>
            <div className='map'>
               <GoogleMaps />
            </div>
            <div className='form'>
               <ContactForm />
            </div>
         </div>
      </div>
   );
};

export default ContactUs;
