import React from 'react';
import GameNumber from '../common/game-number';
import { v4 as uuid } from 'uuid';

const StatistikaIzvucenihBrojeva = ({ showMax = true, data = [], label }) => {
   return (
      <div className='statistika-izvucenih-brojeva'>
         <h1>
            {showMax
               ? 'Највише извучених бројева'
               : 'Најмање извучених бројева'}
         </h1>
         <h2>{label}</h2>
         <div className='container-sib'>
            {data.map((n) => (
               <div key={uuid()}>
                  <GameNumber number={n.number} game='loto' />
                  <div>{n.value}</div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default StatistikaIzvucenihBrojeva;
