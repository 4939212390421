import React, { useEffect } from 'react';
import { RiMenuFoldFill, RiMenuUnfoldFill } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { setResponsiveMenuIsOpen } from '../../store/responsive-utilities-store';

const menuOpenClassName = 'menu-open';
const disableScrollClassName = 'disable-scroll';

const ButtonResponsiveMenu = (props) => {
   const { isOpenResponsiveMenu: isOpen } = useSelector(
      (state) => state.responsiveUtilities
   );
   const dispatch = useDispatch();

   const handleBodyClass = () => {
      const bodyClassList = document.body.classList;
      isOpen
         ? bodyClassList.remove(menuOpenClassName, disableScrollClassName)
         : bodyClassList.add(menuOpenClassName, disableScrollClassName);
   };

   const handleClick = () => {
      handleBodyClass();
      dispatch(setResponsiveMenuIsOpen(!isOpen));
   };

   useEffect(() => {
      return () => {
         handleBodyClass();
      };
   }, [isOpen]);

   return (
      <button type='button' className='btn-menu' onClick={handleClick}>
         {isOpen ? <RiMenuUnfoldFill /> : <RiMenuFoldFill />}
      </button>
   );
};

export default ButtonResponsiveMenu;
