import React from 'react';
import { bottomNavbarLinks } from '../../routes';
import { topNavbarLinks } from '../../routes';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setResponsiveMenuIsOpen } from '../../store/responsive-utilities-store';

const NavbarSmartphone = (props) => {
   const dispatch = useDispatch();
   const activeLinkClass = (navData) => (navData.isActive ? 'activeLink' : '');

   const handleNavLinkClick = () => {
      dispatch(setResponsiveMenuIsOpen(false));
   };

   const getNavbarLinks = (arrLinks) =>
      arrLinks.map((link, i) => (
         <NavLink
            to={link.path}
            className={activeLinkClass}
            key={`${link.path}_${i}`}
            onClick={handleNavLinkClick}
         >
            {link.text}
         </NavLink>
      ));

   return (
      <div className='navbar-responsive'>
         {getNavbarLinks(bottomNavbarLinks)}
         {getNavbarLinks(topNavbarLinks)}
      </div>
   );
};

export default NavbarSmartphone;
