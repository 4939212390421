import React, { useEffect, useState } from 'react';
import Graph from './loto739-statistika-graph';
import StatistikaIzvucenihBrojeva from './loto739-statistika-izvucenih-brojeva';
import { min, max } from './test-data-loto739-statistika-izvucenih-brojeva';
import SelectTransparent from './../common/select-transparent';
import { useDispatch, useSelector } from 'react-redux';
import { loadLotoData } from './../../store/loto-store';
import {
   getNumberOfOccurrences,
   getStatistics
} from '../../services/loto-service';
import { getOccurrenceData } from './../../services/loto-service';
import LoadingInformation from './../common/loading-information';

const games = [
   { text: 'Лото', value: 0 },
   { text: 'Лото плус', value: 1 },
   { text: 'Џокер', value: 2 }
];
const time = [
   { text: 'Од почетка', value: 0 },
   { text: '1 година', value: 1 },
   { text: '6 мјесеци', value: 2 }
];

const Loto739Statistika = (props) => {
   const dispatch = useDispatch();
   const { lotoReport, lotoDetailedReport, loaded } = useSelector(
      (state) => state.lotoStore
   );

   useEffect(() => {
      if (!lotoReport || lotoReport.length === 0) dispatch(loadLotoData());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (lotoReport && lotoReport.length > 0) getData();
   }, [lotoReport]); // eslint-disable-line react-hooks/exhaustive-deps

   const [selectedGame, setSelectedGame] = useState(0);
   const [selectedTime, setSelectedTime] = useState(0);

   const [occurranceData, setOccurranceData] = useState([]);
   const [selectedDateText, setSelectedDateText] = useState('');

   const onGameChange = (ddl) => {
      const { value } = ddl;
      setSelectedGame(value);
   };

   const onTimeChange = (ddl) => {
      const { value } = ddl;
      setSelectedTime(value);
   };

   const getData = () => {
      if (!lotoReport || lotoReport.length === 0) return;

      const { data, date } = getStatistics(
         lotoReport,
         selectedGame,
         selectedTime
      );
      setOccurranceData(getOccurrenceData(data));
      setDate(date);
   };

   const setDate = (date) => {
      if (date.toDateString() === new Date().toDateString())
         setSelectedDateText('Од почетка');
      else {
         setSelectedDateText(`од ${date.toLocaleDateString()} надаље`);
      }
   };

   useEffect(() => {
      getData();
   }, [selectedGame, selectedTime]);

   const getMaxMinOccurrances = (max = true) => {
      const dataCopy = [...occurranceData].sort((a, b) => b.value - a.value);
      return max ? dataCopy.takeFirst(6) : dataCopy.takeLast(6);
   };

   return (
      <div className='loto-7-39-statistika'>
         {!loaded && <LoadingInformation />}
         <h1>ЛОТО СТАТИСТИКЕ</h1>
         <h2>Завирите у историју срећних бројева</h2>
         <p>
            Све информације о учесталости извучених бројева доступне су
            графикону у наставку. У менију одаберите игру и временско раздобље
            које вас занима. Одабрана статистика за ваш одабир појављује се на
            графикону. Црвени стубац показује сваки број. Кликом на стубац
            приказује се информација о томе којико је сваки број извучен у
            одабраном раздобљу.{' '}
         </p>

         <SelectTransparent
            name='game'
            options={games}
            onChange={onGameChange}
            value={selectedGame}
         />
         <SelectTransparent
            name='time'
            options={time}
            onChange={onTimeChange}
            value={selectedTime}
         />

         <Graph data={occurranceData} />

         {occurranceData.length > 0 && (
            <>
               <StatistikaIzvucenihBrojeva
                  data={getMaxMinOccurrances(true)}
                  label={selectedDateText}
               />
               <StatistikaIzvucenihBrojeva
                  label={selectedDateText}
                  data={getMaxMinOccurrances(false)}
                  showMax={false}
               />
            </>
         )}
      </div>
   );
};

export default Loto739Statistika;
