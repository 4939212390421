import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/common/footer';
import GameNavbar from '../components/common/game-navbar';
import { loto739NavbarLinks } from './../routes';
import loto739logo from '../img/loto/loto-logo-sm.png';

const Loto739Page = (props) => {
   return (
      <>
         <GameNavbar
            logo={loto739logo}
            links={loto739NavbarLinks}
            additionClass='loto739-info'
         />
         <div className='loto739-page'>
            <Outlet />
         </div>
         <Footer />
      </>
   );
};

export default Loto739Page;
