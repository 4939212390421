/*
    {
        game:'bingo' | 'bingoPlus',
        numbers: []
    }
*/
export const mapBingoReportToRenderData = (bingoReport) => {
   const getNumbers = (numbersString) => {
      const _numbers = numbersString.split(',');
      return _numbers.map((x) => +x.trim()).filter((x) => x !== 0);
   };

   return bingoReport
      .map((x) => ({
         game: x.type,
         numbers: getNumbers(x.winning_combination),
         date: x.date,
         id: x.id
      }))
      .sort((a, b) => new Date(b.id) - new Date(a.id));
};

/*
    član polja predstavlja podatak retka
    [
        {
            vrsta:'string',
            dobiciSrbija:'',
            dobiciRS:'',
            iznosDobitaka:''
        }
    ]
*/
export const mapLotoDetailedReportToTableData = (lotoDetailedReport) => {
   // lotoDetailedReport.sort((a, b) => +b.row_names - +a.row_names);
   const returnArray = [];
   for (let i = 7; i >= 1; i--) {
      const rowData = lotoDetailedReport.filter((x) => +x.row_names === i);
      returnArray.push(mapToTableRowDataObject(rowData));
   }
   return returnArray;
};

// fn prima polje objekata od kojih mapiram podatke na 1 objekt
const mapToTableRowDataObject = (arr) => ({
   vrsta: getVrstaName(arr, arr[0].row_names),
   dobiciSrbija: arr.find((x) => x.column_names === column_names.srbija).data,
   dobiciRS: arr.find((x) => x.column_names === column_names.republikaSrpska)
      .data,
   iznosDobitaka: arr.find((x) => x.column_names === column_names.iznosDobitaka)
      .data
});

// redak 1 i 2 mijenjaju vrijednost u Loto+ i Džoker+
const getVrstaName = (arr, rowName) => {
   if (rowName === '2') return 'Loto +';
   if (rowName === '1') return 'Džoker +';
   return arr[0].row_names;
};

const column_names = {
   srbija: 'serbia',
   republikaSrpska: 'rs',
   iznosDobitaka: 'amount'
};
