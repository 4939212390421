import React from 'react';
import ContactBannerIcon from './contact-banner-icon';

const ContactBanner = ({ title, content, icon }) => {
   return (
      <div className='contact-banner'>
         <ContactBannerIcon icon={icon} />
         <div className='content'>
            <h2>{title}</h2>
            <div>{content}</div>
         </div>
      </div>
   );
};

export default ContactBanner;
