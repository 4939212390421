import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { pageRoutes } from './routes';
import ProtectedRoute from './components/common/protected-route';
import PageContainer from './components/common/animated-page-container';
import NotFoundPage from './pages/not-found-page';
import ImageCarousel from './components/common/image-carousel';
import Navbar from './components/navbar/navbar';
import NavbarSmartphone from './components/navbar/navbar-smartphone';
import appService, { aosOptions } from './services/app-service';
import aos from 'aos';
import { addScrollCSSclassToBody } from './store/responsive-utilities-store';
import { useDispatch } from 'react-redux';
import LandscapeInformation from './components/common/landscape-information';
import { isMobile } from 'react-device-detect';
import { loadLandingpageData } from './store/landingpage-store';
import { loadAllSliderData } from './store/carousel-store';

const App = (props) => {
   const location = useLocation();
   const dispatch = useDispatch();

   const [isLandscape, setIsLandscape] = useState(false);

   useEffect(() => {
      dispatch(loadLandingpageData());
      dispatch(loadAllSliderData());
      setScreenOrientation();

      aos.init(aosOptions);

      // visina navbar elementa ovisno o scrollu
      // na mobilnim ima neki glitch pa je tamo disejblan
      if (!isMobile) {
         window.addEventListener('scroll', () => {
            appService.handleOnScroll(dispatch, addScrollCSSclassToBody);
         });
      }
      console.log('app.js ucitan...');
      window.addEventListener('resize', () => setScreenOrientation());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const setScreenOrientation = () => {
      /* u landscape položaju obavještava se korisnika da se aplikacija koristi samo u portrait modu */
      const mediaQueryList = window.matchMedia('(orientation: portrait)');
      const isLandscapeOrientation = !mediaQueryList.matches;
      setIsLandscape(isLandscapeOrientation);
   };

   return (
      <>
         {isMobile && isLandscape && <LandscapeInformation />}
         <Navbar />
         <NavbarSmartphone />
         <div className='inner-wrapper'>
            {appService.showCarousel(location.pathname) && (
               <ImageCarousel path={location.pathname} />
            )}
            <AnimatePresence exitBeforeEnter>
               <Routes location={location} key={location.pathname}>
                  {pageRoutes.map((route, i) =>
                     route.protected ? (
                        <Route
                           key={`route${i}`}
                           path={route.path}
                           element={
                              <ProtectedRoute>
                                 <PageContainer
                                    className={route.wrapperClassName}
                                    pageAnimation={route.pageAnimation}
                                 >
                                    {route.element}
                                 </PageContainer>
                              </ProtectedRoute>
                           }
                        >
                           {route.nested &&
                              route.nested.map((nestedRoute, i) => (
                                 <Route
                                    key={`nestedRoute${i}`}
                                    path={nestedRoute.path}
                                    element={nestedRoute.element}
                                 />
                              ))}
                        </Route>
                     ) : (
                        <Route
                           key={`route${i}`}
                           path={route.path}
                           element={
                              <PageContainer
                                 className={route.wrapperClassName}
                                 pageAnimation={route.pageAnimation}
                              >
                                 {route.element}
                              </PageContainer>
                           }
                        >
                           {route.nested &&
                              route.nested.map((nestedRoute, i) => (
                                 <Route
                                    key={`nestedRoute${i}`}
                                    path={nestedRoute.path}
                                    element={nestedRoute.element}
                                 />
                              ))}
                        </Route>
                     )
                  )}
                  <Route path='/' element={<Navigate to='/landingpage' />} />
                  <Route
                     path='*'
                     element={
                        <PageContainer>
                           <NotFoundPage />
                        </PageContainer>
                     }
                  />
               </Routes>
            </AnimatePresence>
         </div>
      </>
   );
};

export default App;
