import http from '../services/http';
import endpoints from '../config/api-config.json';

const initialState = {
   images: [],
   loaded: false
};

const actions = {
   clearImages: 'CLEAR_IMAGES',
   loadImages: 'LOAD_IMAGES',
   setLoadedSliders: 'SET_LOADED_SLIDERS',
   loadAllSliderData: 'LOAD_ALL_SLIDER_DATA'
};

export const imgPaths = {
   landingpage: 'img/landingpage',
   loto_sveoigri: 'img/loto/sveoigri',
   bingo_sveoigri: 'img/bingo/sveoigri'
};

export const loadCarouselImages = (images, prefixPath = '') => ({
   type: actions.loadImages,
   payload: { images, prefixPath }
});

export const loadAllSliderData = () => async (dispatch, getState) => {
   try {
      const getMainSliders = () => http.get(endpoints.landingpageSliders);
      const getBingoSliders = () => http.get(endpoints.bingoSliders);
      // const getNewsdata = () => http.get(endpoints.grebeSliders);

      Promise.all([getMainSliders(), getBingoSliders()]).then((results) => {
         const [main, bingo] = results;

         const payload = {
            main,
            bingo,
            loaded: true
         };

         dispatch({ type: actions.loadAllSliderData, payload });
      });
   } catch (ex) {
      console.log('error-LOAD_ALL_SLIDER_DATA', ex);
   }
};

//  REDUCER
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case actions.setLoadedSliders:
         let { loadedSliders } = action.payload;
         return { ...state, loadedSliders };

      case actions.loadImages:
         let { images, prefixPath } = action.payload;
         images = images.map(
            (img) => `${process.env.PUBLIC_URL + prefixPath}/${img}`
         );
         return { ...state, images };

      case actions.loadAllSliderData:
         let { main, bingo, loaded } = action.payload;
         return { ...state, main, bingo, loaded };

      default:
         return state;
   }
}
